$("#burger-container").on('click', function (e) {
    e.stopPropagation();
    $(this).toggleClass("open");
    $('.menu-container li.has-submenu').removeClass("open");
    $('.menu-container').toggleClass("open");
});
$(".nav-item").on('click', function (e) {
    $(".open").removeClass('open')
});
$("#date").on('click', function (e) {
    $(this).addClass('active')
});

$("input[type=date]").datepicker({
    dateFormat: 'yy-mm-dd',
    startDate: '-10',

    onSelect: function (dateText, inst) {
        $(inst).val(dateText); // Write the value in the input
    }
});

$("input[type=date]").on('click', function () {
    return false;
});
new WOW().init();
if ($('div').hasClass("guests")) {
    window.addEventListener('scroll', function () {
        var guests = document.querySelector('#guests');
        var guestsPosition = guests.getBoundingClientRect();
        var welcome = document.querySelector('#welcome');
        var welcomePosition = welcome.getBoundingClientRect();
        var partner = document.querySelector('#partner');
        var partnerPosition = partner.getBoundingClientRect();
        var contact = document.querySelector('#contact');
        var contactPosition = contact.getBoundingClientRect();
        var sparks = document.querySelector('#sparks');
        var sparksPosition = sparks.getBoundingClientRect();

        if ((contactPosition.top - 500) <= 0) {
            $(".nav-item").removeClass("active");
            $(".contact-nav").addClass("active");
        } else if ((partnerPosition.top - 200) <= 0) {
            $(".nav-item").removeClass("active");
            $(".partner-nav").addClass("active");
        } else if ((guestsPosition.top - 200) <= 0) {
            $(".nav-item").removeClass("active");
            $(".guests-nav").addClass("active");
        } else if ((welcomePosition.top - 200) <= 0) {
            $(".nav-item").removeClass("active");
            $(".welcome-nav").addClass("active");
        } else if ((sparksPosition.top - 200) <= 0) {
            $(".nav-item").removeClass("active");
            $(".sparks-nav").addClass("active");
        }
    });
}

if (document.getElementById("awards")) {
    $(".konkurs-nav").addClass("active")
}

window.addEventListener('scroll', function () {
    var sparks = document.querySelector('#sparks');
    var sparksPosition = sparks.getBoundingClientRect();

    if ((sparksPosition.top + 25) <= 0) {
        $("header").addClass("off")
    } else {
        $("header").removeClass("off")
    }
})

window.addEventListener('scroll', function () {
    var sparks = document.querySelector('#sparks');
    var sparksPosition = sparks.getBoundingClientRect();

    if ((sparksPosition.top + 25) <= 0) {
        $("header").addClass("on")
    } else {
        $("header").removeClass("on")
    }
    // }
});

function myFunction(x) {
    if (x.matches) {
        $(document).ready(function () {
            $(".dawid-sm-main").on('load', function () {
                let dawid = $(".dawid-wrapper").height();
                console.log('$(".dawid-sm-main").height()');
                $(".content-right-wrapper").css("top", (parseInt("204".replace(/px/, "")) + dawid) + "px");
                $(".awards").css({
                    "padding-top": (parseInt("50".replace(/px/, "")) + (dawid / 3)) + "px"
                })
            });
        });
    }
}
var x = window.matchMedia("(max-width: 768px)")
myFunction(x)
x.addListener(myFunction)